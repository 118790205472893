<template>
  <div>
    <data-filter :get-filter-data="getReport" />
    <loading-report v-if="loadingReport === true" />
    <div v-else>
      <counters
        v-if="reportData.counts.sms !== null"
        :counts="reportData.counts"
      />
      <table-details
        v-if="reportData.data.length > 0"
        :detail-data="reportData.data"
      />
    </div>
  </div>
</template>

<script>
import DataFilter from '@/views/Reports/Service/BirthdaySms/DataFilter.vue'
import LoadingReport from '@/views/Reports/Service/BirthdaySms/LoadingReport.vue'
import Counters from '@/views/Reports/Service/BirthdaySms/Counters.vue'
import TableDetails from '@/views/Reports/Service/BirthdaySms/Details.vue'

export default {
  name: 'BirthdaySms',
  components: {
    DataFilter,
    LoadingReport,
    Counters,
    TableDetails,
  },
  computed: {
    loadingReport() {
      return this.$store.getters['birthdaySmsReport/getFilterData']
    },
    filterData() {
      return this.$store.getters['birthdaySmsReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['birthdaySmsReport/getReport']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('birthdaySmsReport/report', this.filterData)
    },
  },
}
</script>
