<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body text-center mt-1">
        <b-spinner />
        <p>Lütfen bekleyiniz...</p>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { BAlert, BSpinner } from 'bootstrap-vue'

export default {
  name: 'LoadingReport',
  components: {
    BAlert, BSpinner,
  },
}
</script>
